<template>
  <div class="product-page">
    <v-row no-gutters>
      <v-col cols="12" class="page-header">
        <span class="page-title">{{ $t('Personalized Products  ') }}</span>
        <!-- <v-btn color="primary" @click.stop="drawer = !drawer">More filters </v-btn> -->
      </v-col>
      <v-col cols="12" class="d-flex align-center">
        <v-spacer></v-spacer>
        <!-- <v-btn color="primary" @click="$router.push({ name: 'website.product-personalized.create' })"
          ><i class="fas fa-plus mr-1"></i>{{ $t('Create Teamplate') }}</v-btn
        > -->
      </v-col>
    </v-row>
    <Products ref="products" />
    <v-dialog persistent v-model="showModalImportProduct" width="700" style="transform-origin: center top">
      <v-card relative>
        <!-- Title -->
        <v-card-title class="headline">Import products by CSV file </v-card-title>
        <!-- Close icon -->
        <div class="close-icon" @click="(showModalImportProduct = false), (overwrite = false)">
          <v-icon>fas fa-times</v-icon>
        </div>
        <v-divider class="mb-3"></v-divider>
        <v-row no-gutters d-flex justify="center">
          <v-col cols="11">
            <div>
              <v-btn elevation="" color="primary" @click="clickUploadFile"
                >Choose file
                <div class="fake-btn"><v-icon large small>mdi-publish</v-icon></div></v-btn
              >
              <span class="ml-4" style="font-weight: 700">{{ fileName }}</span>
            </div>
            <div class="mt-5">
              <p class="m-t m-b-sm">
                Download a
                <a
                  href="https://assets.shopbase.com/sample-file/product/import/product-sample-import.csv?_ga=2.177733477.1978499345.1611903298-1097588301.1611903298"
                  target="_blank"
                  >sample CSV template</a
                >
                to see an example of the format required.
              </p>
            </div>
            <v-checkbox v-model="overwrite">
              <template v-slot:label>
                <div class="label-checkbox">Overwrite any existing products that have the same product handle.</div>
              </template>
            </v-checkbox>
            <input
              ref="fileUploader"
              @change="onChangeImage"
              class="myfile"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              type="file"
            />
          </v-col>
        </v-row>
        <v-divider class="my-3"></v-divider>
        <!-- Footer -->
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="" @click="(showModalImportProduct = false), (overwrite = false)"> Close </v-btn>
          <v-btn :loading="isLoading" color="primary" @click="submitUploadCSV">{{ 'Import CSV' }} </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Modal export products -->
    <modal model="modalExport" title="Export products to CSV file" :callback="submitExportCSV">
      <template slot="content">
        <div>
          <p>This CSV file can update all product information.</p>
          <v-radio-group v-model="selectedMode">
            <v-radio value="current-page" label="Current page"></v-radio>
            <v-radio value="all-products" label="All products"></v-radio>
            <v-radio value="selected-products" label="Selected products"></v-radio>
          </v-radio-group>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import Products from './components/Products';
import { productApi } from '@/apis/product';
export default {
  components: { Products },
  data() {
    return {
      showModalImportProduct: false,
      overwrite: false,
      isLoading: false,
      fileName: 'No file chosen',
      modalExport: false,
      ids: [],
      selectedMode: 'current-page',
      products: [],
      search: null,
    };
  },
  methods: {
    downloadFile(fileName, urlData) {
      var aLink = document.createElement('a');
      aLink.download = fileName;
      aLink.href = urlData;
      var event = new MouseEvent('click');
      aLink.dispatchEvent(event);
    },
    async submitExportCSV() {
      let params = {};
      if (this.selectedMode == 'current-page') {
        let ids = this.$refs.products.document.map(product => {
          return product._id;
        });
        params.ids = ids.join(',');
      } else if (this.selectedMode == 'all-products') {
        params.ids = null;
      } else {
        params.ids = this.ids.join(',');
      }
      try {
        let data = await productApi.exportCSVProduct({ params: params });
        this.downloadFile('export.csv', 'data:text/csv;charset=UTF-8,' + encodeURIComponent(data.data));
      } catch (error) {}
      this.modalExport = false;
    },
    clickUploadFile() {
      this.resetImageUploader();
      this.$refs.fileUploader.click();
    },
    resetImageUploader() {
      this.$refs.fileUploader.value = '';
    },
    deleteFile() {
      this.$emit('callback', { src: null });
    },
    objectId() {
      return this.hex(Date.now() / 1000) + ' '.repeat(16).replace(/./g, () => this.hex(Math.random() * 16));
    },

    hex(value) {
      return Math.floor(value).toString(16);
    },
    async onChangeImage(e) {
      this.formData = null;
      this.fileName = 'No file chosen';
      //   e.target.files.forEach(async file => {
      let file = e.target.files[0];
      let size = file.size;
      console.log(size);
      if (size > 1024 * 1024 * 20) {
        this.$store.commit('setMessages', {
          messages: 'The file is larger more than 20Mb and cannot be uploaded.',
          type: 'error',
        });
      } else {
        this.formData = new FormData();
        this.formData.append('file', file);
        this.formData.append('overwrite', this.overwrite);
        this.formData.append('email', this.$store.state.auth.user.email);
        this.fileName = e.target.files[0].name;
      }

      //   });
    },
    async submitUploadCSV() {
      try {
        this.isLoading = true;
        await productApi.importCSVProduct(this.formData);
        // let type = file.name.split('.')[file.name.split('.').length - 1];
        // let id = this.objectId();

        this.showModalImportProduct = false;
        this.fileName = 'No file chosen';
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
      }
    },
    async loadProducts() {
      let params = {
        page: 1,
        limit: 50,
      };
      try {
        params.search = this.search;
        let data = await productApi.get(params);
        this.products = data.data.products;
      } catch (error) {
        console.log(error);
      }
      console.log(this.search);
    },
  },
  watch: {
    search: function() {
      this.loadProducts();
    },
  },
};
</script>
<style lang="scss">
.myfile {
  display: none;
}
</style>

<template>
  <div class="page-products__products">
    <v-row no-gutters class="elevation-1 mt-2 page__table-container">
      <HeaderTableTabs :currentTab.sync="currentTab" :tabs="tabs" />
      <v-col cols="12">
        <Resource
          :document="document"
          :filter-fields="filter"
          :filter-data="filterData"
          :filter-callback="setFilterData"
          :searchable="searchableField"
          :columns="columns"
          :update-router-callback="getUpdateRouterDataConvert"
          :callback-action="getAction"
          :actionItems="items"
          :deleteResource="getdeleteResource"
          :routeName="updateRouteName"
        />
      </v-col>
      <v-col cols="12" class="d-flex justify-space-between">
        <limit-page :callback="setLimit" />
        <pagination :length="lengthPage" :visible="0" :page.sync="page"></pagination>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Resource from '@/components/Resources';
import filter from '@/const/filters/product-filter';
import resourceListMixin from '@/mixins/resource-list';
import { productApi } from '@/apis/product';
import utilities from '@/helpers/utilities';
import { collectionApi } from '@/apis/collection';
import { imageFake } from '@/const/image';
import { personalizesApi } from '@/apis/personalizes';

export default {
  components: { Resource },
  mixins: [resourceListMixin],
  data() {
    return {
      items: [
        { title: 'Edit Product', action: 'bulk-edit' },
        { title: 'Delete selected products', action: 'remove-product' },
        { title: 'Add tags', action: 'add-tags' },
        { title: 'Remove tags', action: 'remove-tags' },
        { title: 'Add Collection', action: 'add-collection' },
        { title: 'Remove Collection', action: 'remove-collection' },
      ],
      imageFake,
      showModalDelete: false,
      collectionSelectList: [],
      showModalSelectCollection: false,
      idsCollection: [],
      keySearchCollection: '',
      isLoadingButton: false,
      tags: [],
      showModalAddTagsProduct: false,
      ids: [],
      resourceType: 'personalizes',
      // page: 1,
      length: 1,
      action: '',
      count: 1,
      tabs: [
        { name: 'All products', key: 'product' },
        { name: 'Personalized Text', key: 'text' },
        { name: 'Personalized Image', key: 'preview' },
      ],
      testVuex: null,
      columns: {
        Product: {
          // blankLabel: true,
          kind: 'viewProductinPer',
          field: 'title',
        },
        'Product Type': {
          field: 'productType',
          kind: 'title',
        },
        Vendor: {
          field: 'vendor',
          kind: 'title',
        },
      },
      searchableField: 'with title',
      sortable: [],
      filter,
      updateRouteName: 'website.product-personalized.detail',
      isLoadingCollection: false,
    };
  },
  async created() {},
  watch: {
    // curentTab == 0 thì là all product, 1 là Personalized
    currentTab() {
      if (this.currentTab === 2) {
        // this.searchableField = '';
        this.columns = {
          Product: {
            // blankLabel: true,
            kind: 'viewProductPersonalize',
            field: 'title',
          },
          'Product Type': {
            field: 'productType',
            kind: 'title',
          },
          // Vendor: {
          //   field: 'vendor',
          //   kind: 'title',
          // },
          // Actions: {
          //   kind: 'action',
          //   blankLabel: false,
          // },
        };
      }
      if (this.currentTab === 0) {
        // this.searchableField = 'with title';
        this.columns = {
          Product: {
            // blankLabel: true,
            kind: 'viewProductinPer',
            field: 'title',
          },
          'Product Type': {
            field: 'productType',
            kind: 'title',
          },
          Vendor: {
            field: 'vendor',
            kind: 'title',
          },
        };
      }
      if (this.currentTab === 1) {
        // this.searchableField = 'with title';
        this.columns = {
          Product: {
            // blankLabel: true,
            kind: 'viewProductinPerText',
            field: 'title',
          },
          'Product Type': {
            field: 'productType',
            kind: 'title',
          },
          Vendor: {
            field: 'vendor',
            kind: 'title',
          },
        };
      }
      // console.log(this.currentTab);
    },
  },
  methods: {
    getUpdateRouterDataConvert() {
      console.log(`sdfsdfsd`);
    },
    getAction() {},
    async getdeleteResource(item) {
      try {
        let res = await personalizesApi.remove(item.id);
        this.getResources();
        console.log(res);
      } catch (error) {}
    },
  },
};
</script>
<style lang="scss">
.page-products__products {
  background: white;
}
.list-product {
  height: 500px;
  overflow: auto;
}
</style>
